import React, { Component } from 'react';
import { connect } from 'react-redux'

import styles from './index.module.css'
import { actionCreators } from '../../store'
import MessageInput from '../MessageInput'


class MessageInputBox extends Component {
    render() {
        let suggestion_list_temp = this.props.suggestion_list.toJS()
        let suggestion_list = suggestion_list_temp.slice(0, 3)
        // 计算 className
        let suggestion_item_className;
        if (suggestion_list.length === 1) {
            suggestion_item_className = styles.item1;
        } else if (suggestion_list.length === 2) {
            suggestion_item_className = styles.item2;
        } else {
            // suggestion_list.length === 3
            suggestion_item_className = styles.item3;
        }

        return (
            <div className={styles.container}>
                {
                    this.props.active_date === '' && this.props.active_topicid === '' ? null: (
                        <MessageInput />
                    )
                }

                <div className={styles.textarea_explain}>灵石LinkStone可能会犯错，请考虑核实重要信息。</div>

                {
                    this.props.is_thinking || suggestion_list.length === 0 ? null : (
                        <div className={styles.suggestion_box}>
                            <div className={styles.suggestion_title}>猜你也许想问：</div>
                            <div className={styles.suggestion_item_box}>
                                {
                                    suggestion_list.map((item, index) => {
                                        return (
                                            <div
                                                className={`${styles.item} ${suggestion_item_className}`}
                                                title={item.text}
                                                key={index}
                                                onClick={() => { this.onSuggestionClick(item.text) }}
                                            >
                                                <div className={styles.item_text}>{item.text} →</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }

    onSuggestionClick(text) {
        if (text.trim() !== '') {
            this.props.handleSetPromptAction(text)
        }
    }
}

const mapStateToProps = (state) => {
    return {
        is_thinking: state.get('home').get('is_thinking'),
        suggestion_list: state.get('home').get('suggestion_list'),
        active_date: state.get('home').get('active_date'),
        active_topicid: state.get('home').get('active_topicid'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleSetPromptAction(data) {
            dispatch(actionCreators.setPromptAction(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageInputBox)