import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useMemo } from 'react'

import styles from './index.module.css'
import moreImg from '../../../../statics/chat_type_sel_more.png'
import dollarImg from '../../../../statics/overview_shopping_dollar_icon.png'
import { actionCreators } from '../../store'


export default function ScreenStoneModelMall() {
    const dispatch = useDispatch()
    const [componentsList, setComponentsList] = useState([])
    let mall_components_list = useSelector(state => state.get('home').get('mall_components_list'))
    let mall_components_categorys = useSelector(state => state.get('home').get('mall_components_categorys'))

    useEffect(() => {
        let active_category = ''
        let l = mall_components_categorys.toJS()
        for (let item of l) {
            if (item.active) {
                active_category = item.label
                break
            }
        }

        if (active_category) {
            let d = []
            mall_components_list.toJS().forEach(item => {
                if (item.category === active_category) {
                    d.push(item)
                }
            })

            setComponentsList(d)
        }
    }, [mall_components_list, mall_components_categorys])

    const handleShowMore = () => {
        dispatch(actionCreators.isShowScreenPersonalOverview(false))
        dispatch(actionCreators.isShowScreenStoneModelMall(false))
        dispatch(actionCreators.isShowScreenPersonalBackpack(true))
        dispatch(actionCreators.expertRelatedAction())
    }

    const handleClickComponent = (id) => {
        let list = mall_components_list.toJS()
        let new_list = list.map((item) => {
            if (item.id === id) {
                item.has_select = true
            } else {
                item.has_select = false
            }
            return item
        })
        dispatch(actionCreators.setMallComponentsList(new_list))
    }

    let currentComponent = useMemo(() => {
        let list = mall_components_list.toJS()
        let obj = list.find(item => item.has_select === true)
        return obj ? obj : {}
    }, [mall_components_list])

    const handleCategoryChange = (label) => {
        let l = mall_components_categorys.toJS()
        l.forEach(element => {
            if (element.label === label) {
                element.active = true
            } else {
                element.active = false
            }
        })

        dispatch(actionCreators.setMallComponentsCategorys(l))
    }

    return (
        <div className={styles.main}>
            <div className={styles.container}>
                <div className={styles.container_header}>
                    灵石商店
                </div>

                <div className={styles.container_link}>
                    <div className={styles.more_box}>
                        <div
                            className={styles.more_text}
                            onClick={handleShowMore}
                        >
                            查看我的组件
                        </div>
                        <img src={moreImg} className={styles.more_img} alt="icon" />
                    </div>
                </div>

                <div className={styles.container_content}>
                    <div className={styles.container_content_left}>
                        <div className={styles.left_category}>
                            {
                                mall_components_categorys.toJS().map((item) => {
                                    return (
                                        <div
                                            className={`${styles.category_butn} ${item.active ? styles.category_butn_active : null}`}
                                            key={item.label}
                                            onClick={() => {handleCategoryChange(item.label)}}
                                        >
                                            { item.label }
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className={styles.left_components}>
                            {
                                componentsList.map((item) => {
                                    return (
                                        <div
                                            className={`${styles.shopping_model_item} ${item.has_select ? styles.shopping_model_item_select : null}`}
                                            key={item.id}
                                            onClick={() => {handleClickComponent(item.id)}}
                                        >
                                            <div className={styles.shopping_model_img_box}>
                                                <img src={item.avatar} className={styles.shopping_model_img} alt="icon" />
                                            </div>
                                            <div className={`${styles.shopping_model_text_box} ${item.permissions.includes('execute') ? styles.shopping_model_text_box_owned : item.shopping_model_text_box_unowned}`}>
                                                <div className={styles.shopping_model_text_title}>
                                                    {item.name}
                                                </div>
                                                <div className={styles.shopping_model_text_info}>
                                                    {item.overview}
                                                </div>
                                            </div>
                                            {
                                                item.permissions.includes('execute') ? (
                                                    <div className={`${styles.shopping_model_butn} ${styles.shopping_model_owned}`}>
                                                        已拥有
                                                    </div>
                                                ) : (
                                                    item.is_limit_time_free ? (
                                                        <div className={`${styles.shopping_model_butn} ${styles.shopping_model_purchase}`}>
                                                            <div>限时免费</div>
                                                        </div>
                                                    ) : (
                                                        <div className={`${styles.shopping_model_butn} ${styles.shopping_model_purchase}`}>
                                                            <div>需支付：</div>
                                                            <img src={dollarImg} className={styles.model_status_img} alt={'icon'} />
                                                            <div>500</div>
                                                        </div>
                                                    )
                                                )
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>


                    <div className={styles.container_content_right}>
                        {
                            Object.keys(currentComponent).length === 0 ? null : (
                                <>
                                    <div className={styles.right_header_img_box}>
                                        <img src={currentComponent.avatar} className={styles.right_header_img} alt="icon" />
                                    </div>
                                    <div className={styles.right_header_text_box}>
                                        <div className={styles.right_header_text}>{currentComponent.name}</div>
                                        <div className={styles.right_header_status_box}>
                                            {
                                                currentComponent?.permissions?.includes('execute') ? (
                                                    <div className={`${styles.shopping_model_butn} ${styles.shopping_model_owned}`}>
                                                        已拥有
                                                    </div>
                                                ) : (
                                                    currentComponent.is_limit_time_free ? (
                                                        <div className={`${styles.shopping_model_butn} ${styles.shopping_model_purchase}`}>
                                                            <div>限时免费</div>
                                                        </div>
                                                    ) : (
                                                        <div className={`${styles.shopping_model_butn} ${styles.shopping_model_purchase}`}>
                                                            <div>需支付：</div>
                                                            <img src={dollarImg} className={styles.model_status_img} alt={'icon'} />
                                                            <div>500</div>
                                                        </div>
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className={styles.introduce_box}>
                                        <div className={styles.introduce_title}>介绍</div>
                                        <div className={styles.introduce_text}>{currentComponent.overview}</div>
                                    </div>
                                    <div className={styles.example_box}>
                                        <div className={styles.example_title}>例子</div>
                                        <div className={styles.example_text}>{currentComponent.description}</div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}