import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useMemo } from 'react'
import { Button, Modal, } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import styles from './index.module.css'
import dollarImg from '../../../../statics/overview_shopping_dollar_icon.png'
import { actionCreators } from '../../store'
import ComponentListedAddModal from '../ComponentListedModalAdd'
import ComponentListedEditModal from '../ComponentListedModalEdit'


export default function ComponentListedPicture() {
    const dispatch = useDispatch()
    const [componentsList, setComponentsList] = useState([])
    let component_picture_listed = useSelector(state => state.get('admin').get('component_picture_listed'))
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    const handleOpenModal = () => {
        dispatch(actionCreators.currentEditComponentType('picture_kb'))
        setIsModalOpen(true)
    }

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false)
    }

    useEffect(() => {
        dispatch(actionCreators.getComponentListed('document_kb'))
        dispatch(actionCreators.getComponentListed('picture_kb'))
        dispatch(actionCreators.getComponentCategory())
    }, [])

    useEffect(() => {
        setComponentsList(component_picture_listed.toJS())
    }, [component_picture_listed])

    const handleClickComponent = (id) => {
        let list = component_picture_listed.toJS()
        let new_list = list.map((item) => {
            if (item.id === id) {
                item.has_select = true
            } else {
                item.has_select = false
            }
            return item
        })
        dispatch(actionCreators.componentPictureListedAction(new_list))
    }

    let currentComponent = useMemo(() => {
        let list = component_picture_listed.toJS()
        let obj = list.find(item => item.has_select === true)
        return obj ? obj : {}
    }, [component_picture_listed])

    const handleEdit = (e, name) => {
        dispatch(actionCreators.currentEditComponentName(name))
        dispatch(actionCreators.currentEditComponentType('picture_kb'))
        e.stopPropagation(); // 防止点击事件冒泡到 Menu.Item
        setIsEditModalOpen(true)
    }

    const handleDelete = (e, name) => {
        e.stopPropagation(); // 防止点击事件冒泡到 Menu.Item

        Modal.confirm({
            title: '删除组件',
            icon: <ExclamationCircleOutlined />,
            content: '请确认是否要删除该组件以及该组件的相关文件？',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                console.log('OK');
                let data = {
                    name: name
                }
                dispatch(actionCreators.deleteAdminSingleComponent(data))
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    return (
        <div className={styles.main}>
            <div className={styles.container}>
                <div className={styles.container_header}>
                    图片组件
                </div>

                <div className={styles.container_content}>
                    <div className={styles.container_content_left}>
                        <div className={styles.left_components}>
                            {
                                componentsList.map((item) => {
                                    return (
                                        <div
                                            className={`${styles.shopping_model_item} ${item.has_select ? styles.shopping_model_item_select : null}`}
                                            key={item.id}
                                            onClick={() => {handleClickComponent(item.id)}}
                                        >
                                            <div className={styles.shopping_model_img_box}>
                                                <img src={item.avatar} className={styles.shopping_model_img} alt="icon" />
                                            </div>
                                            <div className={styles.shopping_model_text_box}>
                                                <div className={styles.shopping_model_text_title}>
                                                    {item.name}
                                                </div>
                                                <div className={styles.shopping_model_text_info}>
                                                    {item.overview}
                                                </div>
                                            </div>


                                            <div className={styles.action_list}>
                                                <div className={styles.action_public}>
                                                    {
                                                        item.is_public ? "已发布" : "未发布"
                                                    }
                                                </div>
                                                <Button onClick={(e) => {handleEdit(e, item.name)}}>编辑</Button>
                                                <Button onClick={(e) => {handleDelete(e, item.name)}}>删除</Button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={styles.left_components_add}>
                            <Button
                                type="primary"
                                style={{width: '100%', height: '100%'}}
                                onClick={handleOpenModal}
                            >
                                添加新的图片组件
                            </Button>
                        </div>
                    </div>

                    <div className={styles.container_content_right}>
                        {
                            Object.keys(currentComponent).length === 0 ? null : (
                                <>
                                    <div className={styles.right_header_img_box}>
                                        <img src={currentComponent.avatar} className={styles.right_header_img} alt="icon" />
                                    </div>
                                    <div className={styles.right_header_text_box}>
                                        <div className={styles.right_header_text}>{currentComponent.name}</div>
                                    </div>
                                    <div className={styles.introduce_box}>
                                        <div className={styles.introduce_title}>介绍</div>
                                        <div className={styles.introduce_text}>{currentComponent.overview}</div>
                                    </div>
                                    <div className={styles.example_box}>
                                        <div className={styles.example_title}>例子</div>
                                        <div className={styles.example_text}>{currentComponent.description}</div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>

            <ComponentListedAddModal
                isModalOpen={isModalOpen}
                handleCloseModal={handleCloseModal}
            />
            <ComponentListedEditModal
                isModalOpen={isEditModalOpen}
                handleCloseModal={handleCloseEditModal}
            />
        </div>
    )
}