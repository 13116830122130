import React, { Component, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Input, Button, Form, message, Upload, Select, Checkbox } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import styles from './index.module.css'
import arrowImg from '../../../../statics/update_done_butn_arrow_forward.png'
import { actionCreators } from '../../store';
import { findItemByProperty } from '../../../../utils'

function KnowledgeBaseAddModal({isModalOpen, handleCloseModal, componentType}) {
    const dispatch = useDispatch()
    const [creating, setCreating] = useState(false)
    const [fileList, setFileList] = useState([])
    const [sideIconList, setSideIconList] = useState([])
    const [isPublic, setIsPublic] = useState(true)
    const [form] = Form.useForm();
    let knowledge_base_menu_immutable = useSelector(state => state.get('admin').get('knowledge_base_menu'))
    let knowledge_base_menu = useSelector(state => state.get('admin').get('knowledge_base_menu')).toJS()
    let admin_expert_category_list = useSelector(state => state.get('admin').get('admin_expert_category_list')).toJS()
    admin_expert_category_list = admin_expert_category_list.filter(item => item.label !== '所有')
    const [pictureComponents, setPictureComponents] = useState([])
    const [documentComponents, setDocumentComponents] = useState([])

    useEffect(() => {
        let obj = findItemByProperty(knowledge_base_menu, 'key', 'picture_kb')
        if (obj) {
            let picture_components = obj.children.map((item) => {
                return {
                    label: item.label,
                    value: item.label,
                }
            })
            console.log("picture_components = ", picture_components);
            setPictureComponents(picture_components)
        }
    }, [knowledge_base_menu_immutable])

    useEffect(() => {
        let obj = findItemByProperty(knowledge_base_menu, 'key', 'document_kb')
        console.log("useEffect obj = ", obj);
        if (obj) {
            let document_components = obj.children.map((item) => {
                return {
                    label: item.label,
                    value: item.label,
                }
            })
            console.log("document_components = ", document_components);
            setDocumentComponents(document_components)
        }
    }, [knowledge_base_menu_immutable])

    // 移除文件
    const handleRemove = (file) => {
        setFileList((prevFileList) => prevFileList.filter(item => item.uid !== file.uid));
    }

    const handleChange = (info) => {
        console.log(info);
        let newFileList = [...info.fileList];
        newFileList = newFileList.slice(-1);
        setFileList(newFileList);
    }

    // 移除文件
    const handleSideIconRemove = (file) => {
        setSideIconList((prevFileList) => prevFileList.filter(item => item.uid !== file.uid));
    }

    // 移除文件
    const handleSideIconChange = (info) => {
        console.log(info);
        let newFileList = [...info.fileList];
        newFileList = newFileList.slice(-1);
        setSideIconList(newFileList);
    }

    const onFinish = (values) => {
        console.log('Success:', values);
        let model_name = values.model_name.trim()
        let overview = values.overview.trim()
        let description = values.description.trim()
        let category = values.category

        let exists = findItemByProperty(knowledge_base_menu, 'label', model_name)
        if (model_name === '') {
            message.info("知识库名称不能为空或空格")
        } else if (exists) {
            message.info("当前知识库名称已存在，请重新换一个名字~")
        } else if (overview === '') {
            message.info("模型描述信息不能为空")
        } else if (description === '') {
            message.info("给后端模型使用的描述信息不能为空")
        } else {
            let formData = new FormData();
            formData.append('name', model_name)
            formData.append('weight', 0)
            formData.append('category', category)
            formData.append('overview', overview)
            formData.append('description', description)
            formData.append('avatar', fileList[0].originFileObj)
            formData.append('avatar_tb', sideIconList[0].originFileObj)
            // formData.append('is_active', isOverride)

            if (componentType === 'document_kb') {
                formData.append('component_type', 'document_kb')
                formData.append('attached_component_names', values.picture_components ? values.picture_components : null)
            } else {
                formData.append('component_type', 'picture_kb')
                if (isPublic) {
                    formData.append('is_public', isPublic)
                } else {
                    formData.append('attached_component_names', values.document_components ? values.document_components : null)
                }
            }

            console.log("name = ", formData.get('name'));
            console.log("weight = ", formData.get('weight'));
            console.log("category = ", formData.get('category'));
            console.log("overview = ", formData.get('overview'));
            console.log("description = ", formData.get('description'));
            console.log("avatar = ", formData.get('avatar'));
            console.log("avatar_tb = ", formData.get('avatar_tb'));
            // console.log("is_active = ", formData.get('is_active'));

            setCreating(true)
            dispatch(actionCreators.createKnowledgeBase(formData))
                .then(data => { handleCloseModalLocal() })
                .catch(error => {})
                .finally(() => {setCreating(false)})
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const handleCloseModalLocal = () => {
        form.resetFields();
        setFileList([])
        setSideIconList([])
        handleCloseModal()
    }

    return (
        <>
            <Modal
                title={componentType === 'document_kb' ? "添加新的文本组件" : "添加新的图片组件"}
                open={isModalOpen}
                onOk={handleCloseModalLocal}
                onCancel={handleCloseModalLocal}
                // okText="确认"
                // cancelText="取消"
                footer={false}
                destroyOnClose={true}
            >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    preserve={false}
                    form={form}
                >
                    <Form.Item
                        label="组件名称"
                        name="model_name"
                        rules={[
                            {
                                required: true,
                                message: '请输入组件名称',
                            },
                        ]}
                    >
                        <Input placeholder="请输入组件名称" />
                    </Form.Item>


                    <Form.Item
                        label="上传模型 icon"
                        name="model_icon"
                        rules={[{ required: true, message: '请选择侧边栏的知识库图标' }]}
                    >
                        <Upload
                            fileList={fileList}
                            beforeUpload={() => {return false;}}
                            onRemove={handleRemove}
                            multiple={true}
                            accept={"image/*"}
                            onChange={handleChange}
                        >
                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        label="上传模型侧边栏 icon"
                        name="side_model_icon"
                        rules={[{ required: true, message: '请选择侧边栏的知识库图标' }]}
                    >
                        <Upload
                            fileList={sideIconList}
                            beforeUpload={() => {return false;}}
                            onRemove={handleSideIconRemove}
                            multiple={true}
                            accept={"image/*"}
                            onChange={handleSideIconChange}
                        >
                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        label="模型的展示描述"
                        name="overview"
                        rules={[{ required: true, message: '请输入模型的展示描述' }]}
                    >
                        <Input.TextArea
                            // showCount
                            // maxLength={100}
                            style={{
                                resize: 'none',
                                width: '100%',
                                height: 100,
                            }}
                            onChange={(e) => {console.log('Change:', e.target.value)}}
                            placeholder="展示给用户的模型描述信息"
                        />
                    </Form.Item>


                    <Form.Item
                        label="模型的代理描述"
                        name="description"
                        rules={[{ required: true, message: '请输入给后端代理模型的描述信息' }]}
                    >
                        <Input.TextArea
                            // showCount
                            // maxLength={100}
                            style={{
                                resize: 'none',
                                width: '100%',
                                height: 100,
                            }}
                            onChange={(e) => {console.log('Change:', e.target.value)}}
                            placeholder="给后端代理模型的描述信息（灵石据此选择合适的专家模型）"
                        />
                    </Form.Item>

                    <Form.Item
                        label="模型的分类"
                        name="category"
                        rules={[{ required: true, message: '请选择模型对应的分类' }]}
                    >
                        <Select
                            style={{
                                width: 120,
                            }}
                            onChange={(e) => { console.log("选择的数据为 ", e); }}
                            options={admin_expert_category_list}
                        />
                    </Form.Item>

                    {
                        componentType === 'document_kb' ? (
                            <Form.Item
                                label="绑定图片组件"
                                name="picture_components"
                                // rules={[{ required: true, message: '请选择绑定图片组件' }]}
                            >
                                <Select
                                    mode="multiple"
                                    onChange={(e) => { console.log("选择的数据为 ", e); }}
                                    options={pictureComponents}
                                />
                            </Form.Item>
                        ) : (
                            <>
                                <Form.Item
                                    label="是否默认为公共图片组件"
                                    name="public"
                                >
                                    <Checkbox
                                        checked={isPublic}
                                        onChange={() => {setIsPublic(!isPublic)}}
                                    >
                                    </Checkbox>
                                </Form.Item>

                                {
                                    isPublic ? null : (
                                        <Form.Item
                                            label="绑定文本组件"
                                            name="document_components"
                                            // rules={[{ required: true, message: '请选择绑定图片组件' }]}
                                        >
                                            <Select
                                                mode="multiple"
                                                onChange={(e) => { console.log("选择的数据为 ", e); }}
                                                options={documentComponents}
                                            />
                                        </Form.Item>
                                    )
                                }
                            </>
                        )
                    }

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        {
                            creating ? (
                                <Button type="primary" loading>
                                    创建中
                                </Button>
                            ) : (
                                <Button type="primary" htmlType="submit">
                                    创建
                                </Button>
                            )
                        }
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default KnowledgeBaseAddModal