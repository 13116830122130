import { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip, message } from 'antd'

import styles from './index.module.css'
import bgImg from '../../../../statics/ai_link_stone.gif'
import dollarImg from '../../../../statics/level_middle_dollar.png'
import addImg from '../../../../statics/backpack_model_item_add.png'
import removeImg from '../../../../statics/backpack_model_item_remove.png'
import removeAreaImg from '../../../../statics/backpack_remove_area_img.png'
import { actionCreators } from '../../store'
import useUserLevel from '../../../../utils/useUserLevel'
import MyButton from '../../../../common/MyButton'

const LevelModelItem = ({
    require_level, require_added_len, direction, level, added, setDragging, handleDragStart,
    handleAdd, handleRemove
}) => {
    const dispatch = useDispatch()
    const select_mall_component_name = useSelector(state => state.get('home').get('select_mall_component_name'))
    const itemDragOver = (e) => {
        e.preventDefault(); // 必须阻止默认行为才能触发 onDrop
    }

    const itemDrop = (e) => {
        e.preventDefault();
        const data = e.dataTransfer.getData('AddedText');
        const added_item_name = e.dataTransfer.getData('AddedItemName');
        console.log("data = ", data);
        console.log("added_item_name = ", added_item_name);

        if (data === 'AddedDraggedElement') {
            console.log("已经添加");
            handleAdd(added_item_name)
        }
    }

    const itemClick = (name) => {
        console.log("itemClick");
        dispatch(actionCreators.setSelectMallComponentName(name))
    }

    const itemHandleDragStart = (e) => {
        dispatch(actionCreators.setSelectMallComponentName(''))
        handleDragStart(e)
    }

    const itemHandleRemove = (name) => {
        console.log("itemHandleRemove");
        dispatch(actionCreators.setSelectMallComponentName(''))
        handleRemove(name)
    }

    // level >= require_level 代表可开启功能框
    // added.length >= require_added_len 代表已经填充了多少个功能
    // level >= require_level && added.length < require_added_len 可填充但是还未填充
    return (
        <div
            className={`${styles.level_model_item} ${styles[`level_model_item_${direction}_${require_added_len}`]} ${level >= require_level ? styles.level_model_item_available : null} ${added[require_added_len - 1]?.name === select_mall_component_name ? styles.level_model_item_select : null} ${level >= require_level && added.length >= require_added_len ? styles.level_model_item_add : null}`}
            onDragOver={level >= require_level && added.length < require_added_len ? itemDragOver : undefined}
            onDrop={level >= require_level && added.length < require_added_len ? itemDrop : undefined}
            onClick={level >= require_level && added.length >= require_added_len ? () => { itemClick(added[require_added_len - 1].name) } : undefined}
        >
            {
                level >= require_level ? (
                    added.length >= require_added_len ? (
                        <Tooltip placement="top" title={added[require_added_len - 1].name}>
                            <div
                                className={styles.level_model_item_img_box}
                                draggable
                                onDragStart={itemHandleDragStart}
                                onDragEnd={() => setDragging(false)}
                                data-remove_item_name={added[require_added_len - 1].name}
                            >
                                <img src={added[require_added_len - 1].avatar} className={styles.model_item_img} alt="experts" />
                                {
                                    added[require_added_len - 1].name === select_mall_component_name ? (
                                        <img
                                            src={removeImg}
                                            className={styles.model_item_add_img}
                                            alt="remove"
                                            onClick={(e) => {e.preventDefault(); e.stopPropagation(); itemHandleRemove(added[require_added_len - 1].name)}}
                                        />
                                    ) : null
                                }
                            </div>
                        </Tooltip>
                    ) : null
                ) : (
                    <>
                        <p className={styles.level_model_item_text}>Lv.{require_level}</p>
                        <p className={styles.level_model_item_text}>开启</p>
                    </>
                )
            }
        </div>
    )
}

export default function ScreenPersonalBackpack() {
    const dispatch = useDispatch()
    const current_experience = useSelector(state => state.get('home').get('current_experience'))
    const { level, totalExperience } = useUserLevel(current_experience);
    const mall_components_list = useSelector(state => state.get('home').get('mall_components_list'))
    const level_space_data = useSelector(state => state.get('home').get('level_space_data')).toJS()
    const select_mall_component_name = useSelector(state => state.get('home').get('select_mall_component_name'))
    const [added, setAdded] = useState([])
    const [notAdded, setNotAdded] = useState([])
    const [dragging, setDragging] = useState(false);

    const goToModelMall = () => {
        dispatch(actionCreators.isShowScreenPersonalOverview(false))
        dispatch(actionCreators.isShowScreenStoneModelMall(true))
        dispatch(actionCreators.isShowScreenPersonalBackpack(false))
        dispatch(actionCreators.expertRelatedAction())
    }

    useEffect(() => {
        let component_list = mall_components_list.toJS()
        let can_add_list = component_list.filter(component => component.permissions.includes('execute'))

        // 筛选出 has_add > 0 的，并按 has_add 从小到大排序
        const hasAddGreaterThanZero = can_add_list
            .filter(component => component.has_add > 0)
            .sort((a, b) => a.has_add - b.has_add);

        // 筛选出 has_add = 0 的
        const hasAddEqualToZero = can_add_list
            .filter(component => component.has_add === 0);

        setAdded(hasAddGreaterThanZero)
        setNotAdded(hasAddEqualToZero)
    }, [mall_components_list])

    let currentComponent = useMemo(() => {
        let obj = added.find(item => item.name === select_mall_component_name)
        return obj ? obj : {}
    }, [select_mall_component_name])

    const handleRemove = (name) => {
        let component_list = mall_components_list.toJS()
        let current_component = component_list.find(item => item.name === name)

        if (current_component) {
            let d = {
                "knowledge_base_names": [current_component.name]
            }

            dispatch(actionCreators.deleteStoreComponentsListUserForge(d))
                .then((data) => {
                    let new_list = component_list.map(component => {
                        if (component.name === name) {
                            // 返回一个新的对象，更新 has_add 的值
                            return { ...component, has_add: 0 }
                        }

                        return component
                    })

                    dispatch(actionCreators.setMallComponentsList(new_list))
                })
                .catch(() => {})
                .finally(() => {})
        }
    }

    const handleAdd = (name) => {
        let n = level_space_data[`lv${level}`]
        if (n < added.length + 1) {
            message.info("当前空间已经加满")
        } else {
            let component_list = mall_components_list.toJS()
            let current_component = component_list.find(item => item.name === name)

            if (current_component) {
                let d = {
                    "knowledge_base_names": [current_component.name]
                }

                dispatch(actionCreators.postStoreComponentsListUserForge(d))
                    .then((data) => {
                        // 找到最大 has_add 值
                        const maxHasAdd = component_list.reduce((max, component) => {
                            return component.has_add > max ? component.has_add : max;
                        }, 0)

                        // 更新目标对象的 has_add 值
                        let new_list = component_list.map(component => {
                            if (component.name === name) {
                                return { ...component, has_add: maxHasAdd + 1 };
                            }
                            return component
                        })

                        dispatch(actionCreators.setMallComponentsList(new_list))
                    })
                    .catch((error) => {message.error("数据添加失败：" + error.message)})
                    .finally(() => {})
            }
        }
    }

    const handleRemoveAll = () => {
        let component_list = mall_components_list.toJS()
        let d = {
            "knowledge_base_names": []
        }

        if (added.length > 0) {
            // 当不存在已添加的，则不需要发送请求
            dispatch(actionCreators.deleteStoreComponentsListUserForge(d))
            .then((data) => {
                // 更新目标对象的 has_add 值
                let new_list = component_list.map(component => {
                    component.has_add = 0
                    return component
                })

                dispatch(actionCreators.setMallComponentsList(new_list))
            })
            .catch((error) => {message.error("数据添加失败：" + error.message)})
            .finally(() => {})
        }
    }

    const handleAddAll = (components) => {
        let component_list = mall_components_list.toJS()

        // 计算当前 has_add 不为 0 的对象数量
        let nonZeroCount = component_list.reduce((count, component) => {
            return component.has_add > 0 ? count + 1 : count;
        }, 0);

        let n = level_space_data[`lv${level}`]
        // 需要填充的数量
        const fillCount = n - nonZeroCount;

        // 如果已经有 n 个或更多不为 0 的对象，则无需填充
        if (fillCount <= 0) {
            return
        }

        // 最大的 has_add 值，作为新的 has_add 起始点
        const maxHasAdd = component_list.reduce((max, component) => {
            return component.has_add > max ? component.has_add : max;
        }, 0);

        let has_names = []
        // 填充 has_add 值
        let newComponents = component_list.map(component => {
            if (component.has_add === 0 && nonZeroCount < n) {
                nonZeroCount++;
                return { ...component, has_add: maxHasAdd + nonZeroCount };
            } else {
                has_names.push(component.name)
            }
            return component;
        });

        let names = []
        newComponents.forEach((item) => {
            if (item.has_add > 0) {
                names.push(item.name)
            }
        })

        names = names.filter(item => !has_names.includes(item));

        if (names.length > 0) {
            let d = {
                "knowledge_base_names": names
            }

            dispatch(actionCreators.postStoreComponentsListUserForge(d))
                .then((data) => {
                    dispatch(actionCreators.setMallComponentsList(newComponents))
                })
                .catch((error) => {message.error("数据添加失败：" + error.message)})
                .finally(() => {})
        }
    }

    const handleDragStart = (e) => {
        e.dataTransfer.setData('RemoveText', 'RemoveDraggedElement');
        e.dataTransfer.setData('RemoveItemName', e.target.dataset.remove_item_name);
        setDragging(true)
    }

    const handleDragOver = (e) => {
        e.preventDefault(); // 必须阻止默认行为才能触发 onDrop
    }

    const handleDrop = (e) => {
        e.preventDefault();
        const data = e.dataTransfer.getData('RemoveText');
        const remove_item_name = e.dataTransfer.getData('RemoveItemName');
        console.log("data = ", data);
        console.log("remove_item_name = ", remove_item_name);

        if (data === 'RemoveDraggedElement') {
            console.log("已经丢弃");
            handleRemove(remove_item_name)
        }
        setDragging(false);
    }

    const itemDragStart = (e) => {
        e.dataTransfer.setData('AddedText', 'AddedDraggedElement');
        e.dataTransfer.setData('AddedItemName', e.target.dataset.added_item_name);
    }

    const closeComponentShow = () => {
        dispatch(actionCreators.setSelectMallComponentName(''))
    }

    return (
        <div className={styles.main}>
            <div className={styles.container}>
                <div className={styles.container_header}>
                    我的背包
                </div>

                <div className={styles.container_content}>
                    <div className={styles.container_content_left}>
                        <div className={styles.left_level_box}>
                            <div className={styles.level_model_item_box}>
                                {/* <div className={`${styles.level_model_item} ${styles.level_model_item_top_1} ${styles.level_model_item_available}`}>
                                    {
                                        level >= 1 ? (
                                            added.length >= 1 ? (
                                                <Tooltip placement="top" title={added[0].name}>
                                                    <div
                                                        className={styles.level_model_item_img_box}
                                                        draggable
                                                        onDragStart={handleDragStart}
                                                        onDragEnd={() => setDragging(false)}
                                                        data-itemid={added[0].name}
                                                    >
                                                        <img src={added[0].avatar} className={styles.model_item_img} alt="experts" />
                                                    </div>
                                                </Tooltip>
                                            ) : null
                                        ) : (
                                            <>
                                                <p className={styles.level_model_item_text}>Lv.1</p>
                                                <p className={styles.level_model_item_text}>开启</p>
                                            </>
                                        )
                                    }
                                </div> */}
                                <LevelModelItem
                                    require_level={1}
                                    require_added_len={1}
                                    direction="top"
                                    level={level}
                                    added={added}
                                    setDragging={setDragging}
                                    handleDragStart={handleDragStart}
                                    handleAdd={handleAdd}
                                    handleRemove={handleRemove}
                                />
                                <LevelModelItem
                                    require_level={1}
                                    require_added_len={2}
                                    direction="top"
                                    level={level}
                                    added={added}
                                    setDragging={setDragging}
                                    handleDragStart={handleDragStart}
                                    handleAdd={handleAdd}
                                    handleRemove={handleRemove}
                                />
                                <LevelModelItem
                                    require_level={2}
                                    require_added_len={3}
                                    direction="top"
                                    level={level}
                                    added={added}
                                    setDragging={setDragging}
                                    handleDragStart={handleDragStart}
                                    handleAdd={handleAdd}
                                    handleRemove={handleRemove}
                                />
                                <LevelModelItem
                                    require_level={2}
                                    require_added_len={4}
                                    direction="top"
                                    level={level}
                                    added={added}
                                    setDragging={setDragging}
                                    handleDragStart={handleDragStart}
                                    handleAdd={handleAdd}
                                    handleRemove={handleRemove}
                                />
                                <LevelModelItem
                                    require_level={3}
                                    require_added_len={5}
                                    direction="top"
                                    level={level}
                                    added={added}
                                    setDragging={setDragging}
                                    handleDragStart={handleDragStart}
                                    handleAdd={handleAdd}
                                    handleRemove={handleRemove}
                                />
                            </div>

                            <div className={styles.level_middle}>
                                <img src={bgImg} className={styles.level_middle_bg_img} alt="linkstone" />
                                <div className={styles.level_middle_butn}>
                                    <img src={dollarImg} className={styles.dollar_img} alt="dollar" />
                                    <div className={styles.level_middle_butn_text}>N/A</div>
                                </div>
                            </div>

                            <div className={styles.level_model_item_box}>
                                {/* <div className={`${styles.level_model_item} ${styles.level_model_item_bottom_6} ${level >= 4 ? styles.level_model_item_available : null}`}>
                                    {
                                        level >= 4 ? (
                                            added.length >= 6 ? (
                                                <Tooltip placement="top" title={added[1].name}>
                                                    <div className={styles.level_model_item_img_box}>
                                                        <img src={added[1].avatar} className={styles.model_item_img} alt="experts" />
                                                    </div>
                                                </Tooltip>
                                            ) : null
                                        ) : (
                                            <>
                                                <p className={styles.level_model_item_text}>Lv.4</p>
                                                <p className={styles.level_model_item_text}>开启</p>
                                            </>
                                        )
                                    }
                                </div> */}
                                <LevelModelItem
                                    require_level={4}
                                    require_added_len={6}
                                    direction="bottom"
                                    level={level}
                                    added={added}
                                    setDragging={setDragging}
                                    handleDragStart={handleDragStart}
                                    handleAdd={handleAdd}
                                    handleRemove={handleRemove}
                                />
                                <LevelModelItem
                                    require_level={5}
                                    require_added_len={7}
                                    direction="bottom"
                                    level={level}
                                    added={added}
                                    setDragging={setDragging}
                                    handleDragStart={handleDragStart}
                                    handleAdd={handleAdd}
                                    handleRemove={handleRemove}
                                />
                                <LevelModelItem
                                    require_level={5}
                                    require_added_len={8}
                                    direction="bottom"
                                    level={level}
                                    added={added}
                                    setDragging={setDragging}
                                    handleDragStart={handleDragStart}
                                    handleAdd={handleAdd}
                                    handleRemove={handleRemove}
                                />
                                <LevelModelItem
                                    require_level={6}
                                    require_added_len={9}
                                    direction="bottom"
                                    level={level}
                                    added={added}
                                    setDragging={setDragging}
                                    handleDragStart={handleDragStart}
                                    handleAdd={handleAdd}
                                    handleRemove={handleRemove}
                                />
                                <LevelModelItem
                                    require_level={6}
                                    require_added_len={10}
                                    direction="bottom"
                                    level={level}
                                    added={added}
                                    setDragging={setDragging}
                                    handleDragStart={handleDragStart}
                                    handleAdd={handleAdd}
                                    handleRemove={handleRemove}
                                />
                            </div>
                        </div>

                        <div className={styles.left_butn_box}>
                            <MyButton
                                width={'160px'}
                                height={'50px'}
                                onClick={handleRemoveAll}
                                text="一键拆卸"
                                type="default"
                            />
                            <MyButton
                                width={'160px'}
                                height={'50px'}
                                onClick={handleAddAll}
                                text="一键填满"
                                type="primary"
                            />
                        </div>
                    </div>

                    <div className={`${styles.container_content_right} ${select_mall_component_name === '' ? null : styles.container_content_right_select}`}>
                        {
                            select_mall_component_name === '' ? (
                                <>
                                    <div className={styles.model_box}>
                                        <div className={styles.haved_add_box}>
                                            <div className={styles.content_title}>已添加</div>
                                            <div className={styles.add_model_box}>
                                                {
                                                    added.map((item) => {
                                                        return (
                                                            <div className={styles.model_item_box} key={item.id}>
                                                                <div className={`${styles.model_item} ${styles.model_item_remove}`}>
                                                                    <img src={item.avatar} className={styles.model_item_img} alt="experts" />
                                                                    <img
                                                                        src={removeImg}
                                                                        className={styles.model_item_add_img}
                                                                        alt="remove"
                                                                        onClick={() => {handleRemove(item.name)}}
                                                                    />
                                                                    <div className={styles.model_item_grayscale}></div>
                                                                </div>
                                                                <div className={styles.model_item_name} title={item.name}>{item.name}</div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>

                                        {
                                            dragging ? (
                                                <div
                                                    className={styles.remove_area_box}
                                                    onDragOver={handleDragOver}
                                                    onDrop={handleDrop}
                                                >
                                                    <img src={removeAreaImg} className={styles.remove_area_img} alt={"remove"} />
                                                    <div className={styles.remove_area_text}>移至我的背包</div>
                                                </div>
                                            ) : (
                                                <div className={styles.no_add_box}>
                                                    <div className={styles.content_title}>可添加</div>
                                                    <div className={styles.no_add_model_box}>
                                                        {
                                                            notAdded.map((item) => {
                                                                return (
                                                                    <div
                                                                        className={styles.model_item_box}
                                                                        key={item.id}
                                                                        draggable
                                                                        onDragStart={itemDragStart}
                                                                        data-added_item_name={item.name}
                                                                    >
                                                                        <div className={styles.model_item}>
                                                                            <img src={item.avatar} className={styles.model_item_img} alt="icon" />
                                                                            <img
                                                                                src={addImg}
                                                                                className={styles.model_item_add_img}
                                                                                alt="add"
                                                                                onClick={() => {handleAdd(item.name)}}
                                                                            />
                                                                        </div>
                                                                        <div className={styles.model_item_name} title={item.name}>{item.name}</div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>

                                    <div className={styles.right_butn_box}>
                                        <MyButton
                                            width={'100%'}
                                            height={'50px'}
                                            onClick={goToModelMall}
                                            text="前往灵石商店"
                                            type="default"
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div
                                        className={styles.right_header_close_butn}
                                        onClick={closeComponentShow}
                                    ></div>
                                    <div className={styles.right_header_img_box}>
                                        <img src={currentComponent.avatar} className={styles.right_header_img} alt="icon" />
                                    </div>
                                    <div className={styles.right_header_text_box}>
                                        <div className={styles.right_header_text}>{currentComponent.name}</div>
                                        <div className={styles.right_header_status_box}>
                                            {
                                                currentComponent?.permissions?.includes('execute') ? (
                                                    <div className={`${styles.shopping_model_butn} ${styles.shopping_model_owned}`}>
                                                        已拥有
                                                    </div>
                                                ) : (
                                                    currentComponent.is_limit_time_free ? (
                                                        <div className={`${styles.shopping_model_butn} ${styles.shopping_model_purchase}`}>
                                                            <div>限时免费</div>
                                                        </div>
                                                    ) : (
                                                        <div className={`${styles.shopping_model_butn} ${styles.shopping_model_purchase}`}>
                                                            <div>需支付：</div>
                                                            <img src={dollarImg} className={styles.model_status_img} alt={'icon'} />
                                                            <div>500</div>
                                                        </div>
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className={styles.introduce_box}>
                                        <div className={styles.introduce_title}>介绍</div>
                                        <div className={styles.introduce_text}>{currentComponent.overview}</div>
                                    </div>
                                    <div className={styles.example_box}>
                                        <div className={styles.example_title}>例子</div>
                                        <div className={styles.example_text}>{currentComponent.description}</div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}