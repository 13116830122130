import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import styles from './index.module.css'
import { actionCreators } from '../../store'
import { chatTypeIcons } from '../../../../utils'
import projectImg from '../../../../statics/conversation_project_butn.png'
import officeImg from '../../../../statics/conversation_office_butn.png'
import concreteImg from '../../../../statics/conversation_concrete_butn.png'
import arrowImg from '../../../../statics/conversation_ic_arrow next.png'
import MessageInput from '../MessageInput'

const MessageNone = () => {
    const dispatch = useDispatch()
    let user_data = useSelector(state => state.get('login').get('user_data')).toJS()
    let is_thinking = useSelector(state => state.get('home').get('useris_thinking_data'))
    let [currentButn, setCurrentButn] = useState('')
    let [currentButnList, setCurrentButnList] = useState([])

    let butn_list = [
        {
            title: '项目分析',
            children: [
                "帮我完成项目前期分析",
                "帮我分析我的这个项目附近的气候条件如何",
                "帮我分析我的项目地理位置有什么特点",
            ],
            icon: projectImg,
        },
        {
            title: '材料特性',
            children: [
                "混凝土的性能",
                "如此分辨瓷砖好坏",
                "公共场所地面材料推荐",
            ],
            icon: officeImg,
        },
        {
            title: '防火等级',
            children: [
                "写字楼材料有什么防火要求",
                "大理石的防火等级有多少",
                "商场的防火要求",
            ],
            icon: concreteImg,
        },
    ]

    useEffect(() => {
        let obj = butn_list.find(item => item.title === currentButn)
        if (obj) {
            setCurrentButnList(obj.children)
        }
    }, [currentButn])

    const handleCurrentButn = (title) => {
        setCurrentButn(title)
        dispatch(actionCreators.setPromptAction(title))
    }

    const handleCurrentButnChild = (value) => {
        dispatch(actionCreators.setPromptAction(value))
    }

    return (
        <div className={styles.container}>
            <div className={styles.main}>
                <div className={styles.bg_box}>
                    <img src={chatTypeIcons['agent_chat']} className={styles.bg_agent} />

                    <div className={styles.title_llm}>{ `${user_data.username}，你好哇！` }</div>
                    <div className={styles.title_llm}>{ "我是你的室内设计AI助手" }</div>
                </div>

                <div className={styles.message_input}>
                    <MessageInput />
                </div>

                {
                    currentButn === '' ? (
                        <div className={styles.butn_list}>
                            {
                                butn_list.map((item) => {
                                    return (
                                        <div
                                            className={styles.butn_box}
                                            onClick={() => {handleCurrentButn(item.title)}}
                                            key={item.title}
                                        >
                                            <img src={item.icon} className={styles.butn_img} alt="icon" />
                                            <div className={styles.butn_text}>{item.title}</div>
                                            <img src={arrowImg} className={styles.butn_img} alt="icon" />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    ) : (
                        <div className={styles.current_butn_list}>
                            {
                                currentButnList.map((item) => {
                                    return (
                                        <div
                                            className={styles.current_butn_box}
                                            key={item}
                                            onClick={() => {handleCurrentButnChild(item)}}
                                        >
                                            <div className={styles.current_butn_text}>{`${item}  →`}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default MessageNone