import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { message, Select } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';

import styles from './index.module.css'
import { actionCreators } from '../../store'
import MyPieChart from '../../../../common/MyPieChart'
import CategorizeAllManagementTable from '../CategorizeAllManagementTable'


function CategorizeUserManagement() {
    const dispatch = useDispatch()
    const [userData, setUserData] = useState([])
    const [categoryData, setCategoryData] = useState([])
    const [isShow, setIsShow] = useState(false)
    const [categoryDetailsData, setCategoryDetailsData] = useState([])
    const [loading, setLoading] = useState(true)
    const [isRequesting, setIsRequesting] = useState(false)
    const [paramsName, setParamsName] = useState('')
    const [category, setCategory] = useState('vector')     // llm vector
    const [isVerified, setIsVerified] = useState('yes')     // yes no
    let active_uuid = useSelector(state => state.get('admin').get('active_uuid'))
    let user_statistics_data = useSelector(state => state.get('admin').get('user_statistics_data'))

    useEffect(() => {
        handleRequest()
    }, [isVerified, category])

    const handleRequest = () => {
        console.log("副作用函数的执行，获取个人 conversation active_uuid = ", active_uuid);
        if (active_uuid) {
            let list = user_statistics_data.toJS()
            let l = list.filter(item => item.uuid === active_uuid)
            setUserData(l)

            setIsRequesting(true)
            dispatch(actionCreators.fetchUserCategorize(active_uuid, isVerified, category))
            .then(data => {
                setCategoryData(data)
            })
            .catch(error => {})
            .finally(() => { setIsRequesting(false) })
        } else {
            message.info("请先到【用户统计信息】页面指定要查看的用户")
        }
    }

    const onChartClick = (params) => {
        console.log('你点击了:', params);
        let name = params.name
        setParamsName(name)
        // 在这里处理点击事件，例如显示一个弹窗或导航到另一个页面
        if (name) {
            setIsShow(true)
            setLoading(true)
            dispatch(actionCreators.fetchUserCategorizeDetail(name, category, active_uuid))
                .then(data => {
                    let d = data.data
                    const tableData = d.map((item, index) => ({
                        key: item.message_id,
                        value: item.question,
                    }));
                    setCategoryDetailsData(tableData)
                 })
                .catch(error => {})
                .finally(() => { setLoading(false) })
        }
    }

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.title}>
                    单独用户画像管理
                </div>

                <div className={styles.item_box}>
                    <div className={styles.item_title}>手动确认过的数据：</div>
                    <Select
                        defaultValue={isVerified}
                        style={{
                            width: 120,
                        }}
                        onChange={(value) => {setIsVerified(value)}}
                        options={[
                            {
                                value: 'yes',
                                label: 'yes',
                            },
                            {
                                value: 'no',
                                label: 'no',
                            },
                        ]}
                    />
                </div>

                <div className={styles.item_box}>
                    <div className={styles.item_title}>请选择分类的模型：</div>
                    <Select
                        defaultValue={category}
                        style={{
                            width: 120,
                        }}
                        onChange={(value) => {setCategory(value)}}
                        options={[
                            {
                                value: 'vector',
                                label: 'vector',
                            },
                            {
                                value: 'llm',
                                label: 'llm',
                            },
                        ]}
                    />
                </div>
            </div>

            <div className={styles.container}>
                {
                    isRequesting ? (
                        <div className={styles.container_null}>
                            数据正在请求中，请稍等...... <LoadingOutlined />
                        </div>
                    ) : (
                        userData.length > 0 ? (
                            <>
                                <div className={styles.container_header}>
                                    { userData[0].username } 的提问分类饼图
                                </div>
                                {
                                    categoryData.length > 0 ? (
                                        <div className={`${styles.container_main} ${isShow ? styles.container_main_show : null}`}>
                                            <MyPieChart title={"用户问题分类"} data={categoryData} onChartClick={onChartClick} />
                                        </div>
                                    ) : (
                                        <div className={styles.container_null}>
                                            用户还未提问过，暂无该用户统计数据
                                        </div>
                                    )
                                }
                            </>
                        ) : (
                            <div className={styles.container_null}>
                                暂无数据，请去【用户统计信息】画面选定用户
                            </div>
                        )
                    )
                }
            </div>

            {
                isShow ? (
                    <CategorizeAllManagementTable
                        categoryDetailsData={categoryDetailsData}
                        setCategoryDetailsData={setCategoryDetailsData}
                        loading={loading}
                        setIsShow={setIsShow}
                        paramsName={paramsName}
                        paramsCategoryData={categoryData}
                        category={category}
                        handleRequest={handleRequest}
                    />
                ) : null
            }
        </div>
    )
}

export default CategorizeUserManagement